import React from 'react';
import '../styles/Contact.css'; 

const Contact = () => {
  return (
    <div className="get-in-touch-container">
      <h2>Get in Touch</h2>
      <p>To schedule a free consultation, just shoot us an email or <a href="https://calendly.com/elliot-verified/15min">set up a call</a>. Tell us a little bit about your child and their goals for the coming year. </p>
      <a href="mailto:elliot.verified@gmail.com" className="email-button">Email Us</a>
    </div>
  );
};

export default Contact;
