import React from 'react';

import '../styles/Method.css'; 

const Method = () => {
  return (
    <div className="banner-container">
        <div className="banner-image">
        {/* Insert your image here */}
        <img src="./collage.png" alt="Banner" class="mobile-hide" />
    </div>
      <div className="banner-content">
        <div className='banner-p1'>Our Process</div>
        <p>We offer services for every aspect of the college application process: standardized test and subject tutoring, essay workshops, and interview prep. But more than that, we help your child develop their talents and passions into an integrated vision. They should be able to connect the literature they read in class to the movies that they watch, the music they listen to, and even the video games that they play. What we want is for them to become fully conscious of who they are, and to be confident enough to share their excitement with the world.</p>
      </div>
    </div>
  );
};

export default Method;

