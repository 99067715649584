import React from 'react';
import '../styles/Banner.css'; 
const logo = `${process.env.PUBLIC_URL}/Lyceum.svg`;
const friends = `${process.env.PUBLIC_URL}/friends.jpg`;


const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div>
          <img src={logo}></img>
          {/* <h1>Lyceum Tutors</h1> */}
        </div>
        <div className='banner-p1'>Meeting Your Child Where They’re At</div>
        <p>We’re a small group of writers, artists, and entrepreneurs who hold degrees from Princeton, Wesleyan, NYU, and Yale Law, and who all arrived at where we are today by unconventional paths. We understand that your child might have strengths and qualities that aren’t easily quantifiable on paper, and that too often get overlooked by traditional tutoring companies and college counselors. Instead of dressing up your child to fit the conventional mold, we help them find their own voice and present themselves in the best possible light.</p>
      </div>
      <div className="banner-image">
        {/* Insert your image here */}
        <img src={friends} alt="Friends" />
      </div>
    </div>
  );
};

export default Banner;

