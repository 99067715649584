// HomePage.js
import Banner from "../components/banner";
import Services from "../components/services";
import Method from "../components/method";
import Contact from "../components/contact";
import Experts from "../components/experts";

const Home = () => {
    return <>
    <Banner/>
    <Method/>
    <Contact/>
    {/* <Services/> */}
    {/* <Experts/> */}
    {/* <h1>Your kids will go to hell</h1> */}
    </>;
  };
  
  export default Home;
  

  